body {
  margin: 0;
  width: 100%;
  overflow-y: overlay;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.drawer-item > div {
  background-color: #3c7082;
  color: white;
}
.drawer-item > div:hover {
  background-color: #213d47;
}

.MuiListSubheader-root {
  pointer-events: none;
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #3c7082;
  border-color: #3c7082;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #3c7082;
}

.ant-btn-primary {
  background-color: #3c7082;
  background: #3c7082;
}

.ant-btn-primary:hover {
  background-color: #213d47;
  background: #213d47;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit !important;
}

.ant-table {
  overflow-x: scroll;
}

.ant-tree-select-dropdown,
.ant-picker-dropdown,
.ant-modal-wrap,
.ant-modal-mask {
  z-index: 1000000 !important;
}

.demo-editor {
  height: 275px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.dashboard-warning-message-table .ant-table .ant-table-tbody > tr:hover > td {
  color: black;
}
.dashboard-warning-message-table .ant-table {
  background-color: #313131;
}

.dashboard-warning-message-table thead tr th {
  color: white;
  background-color: #313131;
}

.ant-card {
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  border-radius: 5px;
}

.ant-card-head {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 900;
}

/* .demo-toolbar {}
.demo-wrapper {} */
